<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Riservate </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Parco Auto</b-breadcrumb-item>
            <b-breadcrumb-item active> Riservate</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input
              type="text"
              v-model="filter"
              size="md"
              debounce="500"
            />
          </div>
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="carProvider"
        :filter="filter"
        :current-page="currentPage"
        :busy="loading"
        show-empty
        striped
        responsive
        @sort-changed="sortTable"
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle auto...</strong>
          </div>
        </template>
        <template #empty>
          <div class="text-center">
            <h5 class="text-primary-light my-2">
              Non ci sono auto da mostrare
            </h5>
          </div>
        </template>
        <template #cell(opzioni)>
          <span class="mr-2">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  style="color: gray"
                  size="20"
                  icon="LayersIcon"
                ></feather-icon>
              </template>
              <b-dropdown-item>Foto</b-dropdown-item>
              <b-dropdown-item>Foto Perizia</b-dropdown-item>
              <b-dropdown-item>Dettagli Auto</b-dropdown-item>
              <b-dropdown-item>Documenti Associati</b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              class="mx-2"
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  style="color: gray"
                  size="20"
                  icon="EditIcon"
                ></feather-icon>
              </template>
              <b-dropdown-item>Modifica Foto</b-dropdown-item>
              <b-dropdown-item>Modifica Foto Perizia</b-dropdown-item>
              <b-dropdown-item>Modifica Dettagli Auto</b-dropdown-item>
              <b-dropdown-item>Modifica Documenti Associati</b-dropdown-item>
              <b-dropdown-item>Modifica Costi</b-dropdown-item>
              <b-dropdown-item>Modifica Optionals</b-dropdown-item>
              <b-dropdown-item>Modifica Prezzi</b-dropdown-item>
              <b-dropdown-item>Modifica Status</b-dropdown-item>
              <b-dropdown-item>Modifica Ubicazione</b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  style="color: gray"
                  size="20"
                  icon="FileTextIcon"
                ></feather-icon>
              </template>
              <b-dropdown-item>Stampa Scheda</b-dropdown-item>
              <b-dropdown-item>Elenco Prenotazioni</b-dropdown-item>
              <b-dropdown-item>Gestione Pratiche</b-dropdown-item>
              <b-dropdown-item>Gestione Finanziamenti</b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="items.count"
        align="right"
        size="sm"
        class="my-0"
      />
    </b-card>

    <!-- Modali -->

    <b-modal
      id="price_modal"
      ref="price_modal"
      title="Modifica Prezzi"
      ok-only
      ok-title="Salva Modifiche"
    >
      <div class="py-2 px-1">
        <b-form>
          <b-form-group label="Prezzo di vendita al privato">
            <b-form-input
              type="number"
              placeholder="Specifica il nuovo prezzo per la vendita al privato"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Prezzo da mostrare sul sito e portali web">
            <b-form-input
              type="number"
              placeholder="Specifica il nuovo prezzo per la vendita sui portali web"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Prezzo di vendita al privato">
            <b-form-input
              type="number"
              placeholder="Specifica il nuovo prezzo per la vendita al rivenditore"
            ></b-form-input>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardTitle,
  BIcon,
  BDropdown,
  BDropdownItem,
  BTable,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BSpinner,
  BPagination,
} from "bootstrap-vue";
import { Requests } from "@/api";

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardTitle,
    BIcon,
    BDropdown,
    BDropdownItem,
    BTable,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BSpinner,
    BPagination,
  },

  data: () => ({
    fields: [
      { key: "id_utente", label: 'id', sortable: true },
      { key: "marca", sortable: true },
      { key: "modello", sortable: true },
      { key: "versione", sortable: true },
      { key: "immatricolazione_anno", label: 'anno', sortable: true },
      { key: "km", label: 'chilometraggio', sortable: true },
      { key: "targa", sortable: true },
      { key: "telaio", sortable: true },
      "opzioni",
    ],

    items: [],
    filter: null,
    sortBy: "id_auto",
    sortDesc: false,

    //Helpers

    loading: false,
    currentPage: 1,
    perPage: 15,
  }),

  methods: {
    async getAutoReserved(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getAutoReserved(request_data);
        this.items = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async carProvider() {
      await this.getAutoReserved();
      return this.items.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>

<style>
.b-dropdown button {
  padding: 0;
}
</style>
